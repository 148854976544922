import React from "react";





  export const Input = ({ label,register, type, onChange, value, name, disabled, maxlength, min, max }) => {
  return (
    <div className="relative mb-2">
      {" "}
      {/* Add "mb-4" for margin-bottom */}
      <label className="text-gray-500 text-xs font-medium uppercase tracking-wider px-1 left-2 -top-2">
        {label}  
        {
        //disabled?<HiLockClosed className="inline"/>:""
        }
      </label>
      <input
   
        autoComplete="off"
        value={value}
        min={min}
        max={max}
        //maxlength={maxlength}
        className={`p-2 pl-2 w-full focus:outline-success-200 ${disabled ? "border-2 " : "border-primary-300 border border-gray-300"} `}
        type={type}
        onChange={onChange}
        name={name}
        disabled={disabled}
      />
    </div>
  );
};

