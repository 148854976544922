import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../components/Header";

export const AppContent = () => {

return(

<div className="bg-gray-100 h-screen w-full flex flex-col gap-2">
    <Header />
<div className="flex p-2">

<Outlet/>
</div>
</div>

)

}