import React from "react";






  export const Button = ({ label, onClick,icon,type }) => {
  return (
   
      <button className={"flex gap-2 items-center justify-center p-2" + (type=="delete"?" bg-red-500 hover:bg-red-700":" bg-primario hover:bg-secondario") +" text-white  transition hover:duration-150"} onClick={onClick}>{icon}{label}</button>
  );
};



