import React, { ReactNode } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isLogin } from '../utils/login';




const PrivateRoute =({ children })=> {
    const logok = isLogin();//isLogin();


    return logok ? children : <Navigate to="/login" />;
  }

export default PrivateRoute;
