import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Login } from './pages/Login';
import { AppContent } from './pages/AppContent';
import PrivateRoute from './components/PrivateRoute';
import { Homepage } from './pages/Homepage';
import { Scheda } from './pages/Scheda';

function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path="/login" element={<Login />} />
        <Route
            path="/"
            element={
              <PrivateRoute>
                <AppContent />
              </PrivateRoute>
            }
          >
  <Route path="/" element={<Homepage  />} />
  <Route path="/scheda" element={<Scheda  />} />
          </Route>

        </Routes>
    </BrowserRouter>
  );
}

export default App;
