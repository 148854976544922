


export const isLogin = ()=>{

    if(sessionStorage.getItem("login") == "ok"){
        return true
    }else{
        return false
    }
    
    
    }