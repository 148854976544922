export const calcYear = (dataString) => {
    const data = new Date(dataString);
    const oggi = new Date(); // Data corrente
    const annoCorrente = oggi.getFullYear(); // Anno corrente
    const meseCorrente = oggi.getMonth() + 1; // Mese corrente (da 1 a 12)
    const giornoCorrente = oggi.getDate(); // Giorno corrente
    const annoNascita = data.getFullYear();
    const meseNascita = data.getMonth() + 1; // Mese di nascita (da 1 a 12)
    const giornoNascita = data.getDate(); // Giorno di nascita

    let eta;

    if (meseCorrente > meseNascita || (meseCorrente === meseNascita && giornoCorrente >= giornoNascita)) {
        // La data di nascita è già passata nell'anno corrente
        eta = annoCorrente - annoNascita;
    } else {
        // La data di nascita non è ancora passata nell'anno corrente
        eta = annoCorrente - annoNascita - 1;
    }

    return eta >= 0 ? eta : 0;
};