import React from "react";
import logo from '../assets/logo.jpg';
import { useNavigate } from "react-router-dom";
import { Button } from "./Button";
import { FiLogOut } from "react-icons/fi";



export const Header = () => {
const navigate = useNavigate();

return(
<header className="flex flex-row bg-white p-2 shadow-md justify-between items-center">
  <div>
    <img src={logo} className="w-32 cursor-pointer" onClick={() => navigate("/")} />
  </div>
  <div>
    <Button icon={<FiLogOut />} label={"Logout"} onClick={() => {sessionStorage.removeItem("login"); navigate("/login");}} />
  </div>
</header>


)



}