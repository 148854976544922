import axios from 'axios';

export default axios.create({
	//baseURL: 'http://localhost:3000/api/',
	baseURL:"https://webrequiem.cloud/api/",
	//baseURL: 'http://srvbelloni:3000/api/',
	withCredentials: true, //Necessario per salvare i cookies
	headers: {
		//Necessario per salvare i cookies
		'Content-type': 'application/json',
	},
	//baseURL:'http://localhost:8080/api/'
});

//https://web.millergroup.it/api/

//http://localhost:
