import React, { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { DataGrid, GridToolbarQuickFilter, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import { convertDate } from "../utils/convertDate";
import { FiArchive, FiCheck, FiCircle, FiDelete, FiEdit, FiPlus } from "react-icons/fi";
import { HiCheck, HiCheckCircle } from "react-icons/hi";
import { HiXCircle } from "react-icons/hi2";
import { Modal } from "../components/Modal";
import { FaCircle } from "react-icons/fa";
import { CustomCheckbox } from "../components/Checkbox";
/*
const columns = [
  { field: 'col1', headerName: 'Inserito il:', width: 150 },
  { field: 'col2', headerName: 'Data morte', width: 150 },
  { field: 'col3', headerName: 'Nome Cognome', width: 150 },
  { field: 'col4', headerName: 'Data funerale', width: 150 },
  { field: 'col5', headerName: 'Completato', width: 150 },
  { field: 'col6', headerName: 'Servizio', width: 150 },
  { field: 'col7', headerName: 'Saldato', width: 150 },
  { field: 'col8', headerName: 'Modulistica', width: 150 },
];*/






export const Homepage = () => {
  const [schede, setSchede] = useState([])
  const [schedeVisibili, setSchedeVisibili] = useState([])
  const [row,setRow] = useState(null);
  const [open,setOpen] = useState(false);
  const [checkVal,SetCheckVal] = useState(false);
  const navigate = useNavigate();


const handleArchiviati = (val) => {


if(val) {

  SetCheckVal(true)
  setSchedeVisibili(schede)

}else{
  SetCheckVal(false)
  setSchedeVisibili(schede.filter(el => el.archiviato != 1))

}

}







  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      <CustomCheckbox value={checkVal} label="Archiviati" onClick={(el) => {handleArchiviati(el.target.checked)}} />
      </GridToolbarContainer>
    );
  }


  useEffect(() => {
    loadSchede();
  }, [])


  const loadSchede = async () => {

    let resp = await api.get("/schede");

    setSchede(resp.data.data)
    setSchedeVisibili(resp.data.data.filter(el => el.archiviato != 1))


  }


const handleDelete = async (row_sel) => {
console.log(row_sel)
await setRow(row_sel);

setOpen(true)

}



  const columns = [
 
   
    {
      field: "nominativo",
      headerName: "Funerale",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "data_decesso",
      headerName: "Data decesso",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell:(params) => convertDate(params.row.data_decesso)
    },
    {
      field: "data_funerale",
      headerName: "Data funerale",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell:(params) => convertDate(params.row.data_funerale)//convertDate(params.row.data_creazione)
    },
    {
      field: "pagato",
      headerName: "Saldato",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell:(params) => <div className="flex  justify-center items-center h-full w-full">{params.row.pagato ==1?<FaCircle size={20} className="text-green-500"/>:<FaCircle  size={20} className="text-red-500"/>}</div> //convertDate(params.row.data_creazione)
    },
 
    {
      field: "*",
      headerName: "Azioni",
      width:"100",
      headerAlign: "center",
      align: "center",
      renderCell:(params) => <div className="flex h-full justify-center items-center gap-2">{params.row.archiviato==1?<Button icon={<FiArchive />} />:""}<Button icon={<FiEdit />} onClick={() => navigate("/scheda", { state: { operazione: "modifica", scheda: params.row } })}/><Button icon={<FiDelete />} type="delete" onClick={() => handleDelete(params.row)}/></div>
    },
  ];
  


  return (
    <>
    <ModalDelete open={open} scheda={row} setOpen={setOpen} reload={loadSchede}/>
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-row-reverse gap-2">
          <div>
            <Button icon={<FiPlus />} label="Nuovo" onClick={() => navigate("/scheda", { state: { operazione: "inserimento" } })} />
          </div>

        </div>
        <div className="flex flex-row gap-2 h-[70vh] w-full bg-white">
          <DataGrid
            slots={{
              toolbar: CustomToolbar,
            }}
            onCellDoubleClick={(params) => navigate("/scheda", { state: { operazione: "modifica", scheda: params.row } })}
            rows={schedeVisibili} columns={columns} />
        </div>
      </div>
    </>
  )

}




const ModalDelete = ({open,scheda,setOpen,reload}) => {


  const handleDelete = async() =>{

    try{
      await api.delete("/schede/"+scheda.id);
      alert("Scheda eliminata con successo");

      await reload();
      setOpen(false);
    }catch{
      alert("Errore durante l'eliminazione")
    }



  }

  return(<Modal open={open} onClose={() => setOpen(false)}>

    Attenzione sei sicuro di voler eliminare la scheda di <b>{scheda?.nominativo}</b>? L'azione è irreversibile.

<div className="flex flex-row-reverse mt-4">
    <Button label={"Elimina"} onClick={handleDelete}>ELIMINA</Button>
</div>
  </Modal>)




}