const CITIES = require('./codes.json')
export const calcDataFromCf = (cf) => {



    // Controllo la lunghezza e la validità del codice fiscale
    if (cf.length !== 16 || !/^[A-Z0-9]+$/.test(cf)) {
        throw new Error("Codice fiscale non valido");
    }

    const months = {
        'A': '01', 'B': '02', 'C': '03', 'D': '04', 'E': '05', 'H': '06', 
        'L': '07', 'M': '08', 'P': '09', 'R': '10', 'S': '11', 'T': '12'
    };

    // Estrazione anno di nascita
    let year = parseInt(cf.substring(6, 8), 10);
    year += (year > 20) ? 1900 : 2000;

    // Estrazione mese di nascita
    const monthCode = cf.charAt(8);
    const month = months[monthCode];

    // Estrazione giorno di nascita e sesso
    let day = parseInt(cf.substring(9, 11), 10);
    let gender = 'M';
    if (day > 31) {
        gender = 'F';
        day -= 40;
    }

    // Composizione della data di nascita
    const birthDate = new Date(year, parseInt(month) - 1, day);

    // Estrazione luogo di nascita (i primi 4 caratteri del codice di nascita)
    const birthPlaceCode = cf.substring(11, 15);
    // Esempio di lookup table per il luogo di nascita
   
    const birthPlace = CITIES[birthPlaceCode] || null;

    return {
        birthDate: birthDate,
        gender: gender,
        year: year,
        month: month,
        day: day,
        birthPlace: birthPlace
    };
};