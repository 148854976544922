import React from "react";
import { HiLockClosed } from "react-icons/hi2";

export const CustomCheckbox = ({
  label,
  type,
  onClick,
  value,
  name,
  disabled,
}) => {

  return (
    <div className="inline-flex items-center">
      <label
        className="relative flex cursor-pointer items-center rounded-full p-3"
        for={name}
        data-ripple-dark="true"
      >
        <input
         
          id={name}
          type="checkbox"
          checked={value}
          onClick={onClick}
          name={name}
          className={`before:content[''] peer relative h-5 w-5 cursor-pointer  rounded-md border border-blue-slate-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primario checked:bg-primario checked:before:bg-primario hover:before:opacity-10 ${disabled ? "border-2 border-primary-300" : "border border-gray-300"}`}
        />
        <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="1"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </label>
      <label
        className="mt-px cursor-pointer select-none font-medium text-gray-500 uppercase"
        for={name}
      >
        {label}  {disabled?<HiLockClosed className="inline"/>:""}
      </label>
    </div>
    /*  
    <div className="relative">
      <label className=" text-gray-500 text-xs font-medium uppercase tracking-wider bg-white px-1 left-2 -top-2">
        
      </label>
      <input
        
        className="border border-gray-300 rounded-lg p-2 pl-2 w-full focus:outline-primary-700"
        type={type}
        
      />
    </div>*/
  );
};
