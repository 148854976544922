import React from "react";
import { createPortal } from 'react-dom';




export const Modal = ({children,open,onClose}) => {

return(
open && (
    createPortal(
        <div  onClick={onClose} className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-4 ">
          <div className="modal-content flex flex-col gap-2">
            <div className="flex flex-row-reverse">
          <button className="hover:bg-red-100 " onClick={onClose}
          >
            X
          </button>
          </div>
          <div>
            {children}
            </div></div>
   
        </div>
      </div>, document.getElementById("modal")))

    
)


}