import React, { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { DataGrid, GridRowsProp, GridColDef, itIT, GridToolbarQuickFilter, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import api from "../api/api";
import { FiPrinter, FiSave, FiChevronDown, FiMove, FiCircle, FiSkipBack, FiArrowLeft } from "react-icons/fi";
import { CustomCheckbox } from "../components/Checkbox";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import jsPDF from 'jspdf';
import box from '../assets/logo.jpg'
import { convertDate } from "../utils/convertDate";
import { Modal } from "../components/Modal";
import { convertDateToInput } from "../utils/convertDateToInput";
import { FaCircle } from "react-icons/fa";
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";
import { calcYear } from "../utils/calcYear";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/it';
import moment from "moment";
import { calcDataFromCf } from "../utils/calcDataFromCf";

let defaultRichiedente = {
    id: 0,
    id_scheda: 0,
    cognome: "",
    nome: "",
    telefono: 0,
    luogo_nascita: "",
    data_nascita: new Date(),
    citta_residenza: "",
    via_residenza: "",
    civico_residenza: "",
    codice_fiscale: "",
    numero_documento: "",
    luogo_rilascio_documento: "",
    data_rilascio_documento: null,
};




const luoghi_trasporto =
    [
        { id: 2, detail: "Prosecuzione periodo osservazione", dettaglio_documento: "Prosecuzione periodo osservazione ai sensi della L. Reg. Lomb. 4/2019 e del Reg 6/2004 Reg. Lomb. e succ. mod e int.presso: abitazione/Ospedale/RSA/Casa funeraria nel Comune di: " },
        { id: 1, detail: "Funerale", dettaglio_documento: "Funerale " },
        { id: 3, detail: "Inumazione/tumulazione", dettaglio_documento: "Inumazione/tumulazione Cimitero del Comune di: " },
        { id: 4, detail: "Cremazione", dettaglio_documento: "Cremazione presso il forno crematorio nel Comune di: " },
        { id: 5, detail: "Tumulazione", dettaglio_documento: "Tumulazione delle risultanti ceneri nel Cimitero del Comune di: " },
        { id: 6, detail: "Affido", dettaglio_documento: "Affido delle risultanti ceneri al famigliare avente diritto." },
        { id: 7, detail: "Dispersione", dettaglio_documento: "Dispersione delle risultanti ceneri al famigliare avente diritto." },



    ]



export const Scheda = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const [atto, setAtto] = useState({ id_scheda: 0, numero_parenti: 0, stimolatore_cardiaco: 0, data_stampa: null })
    const [trasporto, setTrasporto] = useState({

        id_scheda: 0,
        data_trasporto: new Date(),
        orario_trasporto: null,
        luogo_trasporto: "",
        luogo_comune: "",
        sosta: "",
        data_stampa: null


    });
    const [operazione, setOperazione] = useState(null);
    const [scheda, setScheda] = useState({
        id: 0,
        numero_fattura: 0,
        data_fattura: new Date(),
        telefono: "",
        telefono2: "",
        data_funerale: null,
        disposizione: 0,
        pagato: 0,
        completato: 0,
        pagato: 0,
        annullato: 0,
        data_funerale: new Date(),
        data_creazione: new Date(),
        disposizione: 0,
        preventivo: "",
        acconto: "",
        saldo_a_pagare: "",
        utente: sessionStorage.getItem("username"),
        sede: 0,
        tipologia_affidamento: 0,
        nome_affidatario: "",
        qualita_affidatario: ""


    });
    const [defunto, setDefunto] = useState({
        id: 0,
        id_scheda: 0,
        cognome: "",
        nome: "",
        luogo_nascita: "",
        data_nascita: null,
        anni: 0,
        luogo_decesso: "",
        orario_decesso: "",
        milano: 0,
        via: "",
        data_decesso: null,
        codice_fiscale: "",
        luogo_residenza: "",
        via_residenza: "",
        civico_residenza: "",
        dal: null,
        cittadinanza: "",
        genitore_1: "",
        genitore_2: "",
        stato_civile: "",
        coniuge: "",
        luogo_atto_coniuge: "",
        data_coniuge: "",
        data_nascita_coniuge: "",
        luogo_coniuge: "",
        data_decesso_coniuge: "",
        professione: "",
    });
    const [richiedente, setRichiedente] = useState({
        id: 0,
        id_scheda: 0,
        cognome: "",
        nome: "",
        telefono: 0,
        luogo_nascita: "",
        data_nascita: null,
        citta_residenza: "",
        via_residenza: "",
        civico_residenza: "",
        codice_fiscale: "",
        numero_documento: "",
        luogo_rilascio_documento: "",
        data_rilascio_documento: null,
        parentela: ""
    });

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter />

            </GridToolbarContainer>
        );
    }


    const loadScheda = async (operazione) => {

        if (operazione === "modifica") {

            let resp_scheda = await api.get(`/schede/${location.state.scheda.id_scheda}`);
            let resp_defunto = await api.get(`/defunto/${location.state.scheda.id_scheda}`);
            let resp_richiedente = await api.get(`/richiedente/${location.state.scheda.id_scheda}`);
            let res_trasportp = await api.get("/istanza_trasporto/" + location.state.scheda.id_scheda)
            let res_atto = await api.get("/atto_notorieta/" + location.state.scheda.id_scheda)


            setScheda(resp_scheda.data[0]);
            setDefunto(resp_defunto.data[0]);
            setRichiedente(resp_richiedente.data[0]);
            setTrasporto(res_trasportp.data[0]);
            setAtto(res_atto.data[0])
        } else {

        }


    }

    useEffect(() => {
        console.log(location.state.operazione)
        setOperazione(location.state.operazione)
        loadScheda(location.state.operazione)
    }
        , [])




    const handleSave = async () => {

        if (operazione === "inserimento") {
            let scheda_temp = { ...scheda }
            let resp_scheda = await api.post("/schede", { scheda: scheda });
            let id = resp_scheda.data.message.insertId;
            scheda_temp.id = id;
            let richiedente_temp = { ...richiedente }
            richiedente_temp.id_scheda = id;
            let defunto_temp = { ...defunto }
            defunto_temp.id_scheda = id;
            let resp_defunto = await api.post("/defunto", { defunto: defunto_temp });
            let id_defunto = resp_defunto.data.message.insertId;
            defunto_temp.id = id_defunto;
            let resp_richiedente = await api.post("/richiedente", { richiedente: richiedente_temp });
            let id_richiedente = resp_richiedente.data.message.insertId;
            richiedente_temp.id = id_richiedente;
            setScheda(scheda_temp)
            setRichiedente(richiedente_temp)
            setDefunto(defunto_temp)

            let trasporto_temp = { ...trasporto };
            trasporto_temp.id_scheda = id;
            let res = await api.post("/istanza_trasporto", { istanza: trasporto_temp })
            let id_istanza = res.data.message.insertId;

            trasporto_temp.id = id_istanza;

            setTrasporto(trasporto_temp)

            let atto_temp = { ...atto };
            atto_temp.id_scheda = id;
            let res_atto = await api.post("/atto_notorieta", { atto: atto_temp })
            let id_atto = res.data.message.insertId;

            atto_temp.id = id_istanza;

            setAtto(atto_temp)




            alert("inserito con successo")
            setOperazione("Modifica")
        } else {

            let resp_scheda = await api.put("/schede", { scheda: scheda });
            let resp_defunto = await api.put("/defunto", { defunto: defunto });
            let resp_richiedente = await api.put("/richiedente", { richiedente: richiedente });
            let res_istanza = await api.put("/istanza_trasporto", { istanza: trasporto });
            let res_atto = await api.put("/atto_notorieta", { atto: atto })
            alert("modificato con successo")
        }




    }




    //#region Stampa dei vari documemti

    const handleStampa = (e) => {
        e.preventDefault();
        if (operazione === "inserimento") {
            alert("Attenzione, per stampare devi prima salvare la scheda")
            return;
        }
        handleSave();
        const doc = new jsPDF('landscape');

        const currentDate = new Date().toLocaleDateString('it-IT');
        doc.setFontSize(10);
        doc.addImage(box, 'PNG', 60, 3, 40, 20);
        doc.text(`Impresa Funebre BELLONI GIUSEPPE - via G.Dezza n°47 MELEGNANO(MI) `, 17, 28);
        doc.text(`Agenzia di : Melegnano - Vizzolo P. - Paullo - Mulazzano - Mediglia - Pantigliate `, 15, 33);
        doc.text(`Tel 02.9831945 - fax 02.9830146`, 45, 38);


        doc.text(`Il sottoscritto: ${richiedente.cognome + " " + richiedente.nome}`, 5, 45);
        doc.text(`in qualità di: ${richiedente.parentela}`, 90, 45);
        doc.text(`nato a: ${richiedente.luogo_nascita}`, 5, 52);
        doc.text(`il: ${convertDate(richiedente.data_nascita)}`, 90, 52);



        doc.text(`codice fiscale n°: ${richiedente.codice_fiscale}`, 5, 59);
        //doc.text(`Il giorno: ${convertDate(richiedente.data_nascita)}`, 5, 62);
        // Colonna destra
        doc.text(`Residente a: ${richiedente.citta_residenza}`, 5, 66);
        doc.text(`in via/piazza: ${richiedente.via_residenza + " " + richiedente.civico_residenza}`, 5, 73);


        doc.text(`carta identità/altro n°: ${richiedente.numero_documento}`, 5, 80);
        doc.text(`rilasciata/o il: ${convertDate(richiedente.data_rilascio_documento)}`, 5, 87);

        doc.setFont(undefined, 'bold')
        doc.text(`Dichiara che le generalità del defunto/a sono `, 35, 95);
        doc.setFont(undefined, 'normal')
        doc.text(`Cognome: ${defunto.cognome}`, 5, 102);
        doc.text(`Nome: ${defunto.nome}`, 5, 109);
        doc.text(`Nato/a a: ${defunto.luogo_nascita}`, 5, 116);
        doc.text(`Il: ${convertDate(defunto.data_nascita)}`, 110, 116);
        //doc.text(`Il: ${defunto.data_nascita}`, 110, 100);
        //doc.text(`Anni: ${defunto.anni} `, 10, 110);
        doc.text(`deceduto/a :  ${defunto.luogo_decesso + " - " + defunto.via}`, 5, 123);
        doc.text(`Il :  ${convertDate(defunto.data_decesso)}`, 110, 123);
        //doc.text(`Via: ${defunto.luogo_decesso}`, 10, 120);
        //doc.text(`Il: ${convertDate(defunto.data_decesso)}`, 110, 120);
        doc.text(`Codice fiscale n°: ${defunto.codice_fiscale}`, 5, 130);
        doc.setFont(undefined, 'bold');
        doc.text(`SU MIO DIRETTO INVITO E LIBERA SCELTA ai sensi di:`, 30, 138);
        doc.setFont(undefined, 'normal');
        doc.text(`- Legge. Reg, Lombardia n° 22/2003 art. 8 comma 4e succ. mod. ed integrazioni,`, 5, 145);
        doc.text(`- Regolamento Reg. Lombardia 06/2004 art. 33 comma 2 e succ. mod. ed integrazioni,`, 5, 152);
        doc.text(`- Circolare Direzione Generale Sanità Regione Lombardia n° 19/06,`, 5, 159);
        doc.text(doc.splitTextToSize(`- Legge n° 675/96 " tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali".`, doc.internal.pageSize.getWidth() / 2 - 5), 5, 166);




        doc.text(doc.splitTextToSize(`Conferisco mandato all'Impresa Funebre in calce ad eseguire tutte le pratiche amministrative, a rappresentarmi presso le competenti Autorità italiane e di altri Stati ed a corrispondere in mia vece oneri e diritti eventuali dalle stesse applicati.
 Richiedo e commissiono alla stessa i servizi e le forniture di seguito specificati, impegnandomi al saldo del tutto per una somma pari ad € : ${scheda.preventivo}
 Esonero codesta Impresa Funebre da ogni responsabilità per inconvenienti o danni imputabili alla Pubblica Amministrazione, a Servizi Pubblici ed autorità ecclesiastiche o
 Laiche`, doc.internal.pageSize.getWidth() / 2 - 5), 5, 174);


        //TUTTO A Destra
        let x_right = doc.internal.pageSize.getWidth() / 2 + 5

        // QUESTO E PER MILANO doc.text(doc.splitTextToSize(`Autorizzo codesta impresa ad avvalersi della collaborazione per il disbrigo delle pratiche amministrative presso il Comune di Milano della ditta La nuova Almas.`, doc.internal.pageSize.getWidth()/2 -5 ), x_right, 10);
        doc.text(`Richiedo e commissiono alla stessa i servizi e le forniture di seguito specificati:`, x_right, 25);

        doc.rect(x_right, 32, 3, 4);
        if (scheda?.trasporto_salma == 1) { doc.text(`x`, x_right + 0.5, 35) }
        doc.text(`Trasporto salma/domicilio immediato (ai sensi di art.39 L. Reg. Lombardia n°22/2003)`, x_right + 5, 35);
        doc.text(`da ` + (scheda?.trasporto_salma == 1 ? scheda.trasporto_salma_da : ""), x_right + 5, 42);
        doc.text(`a ` + (scheda?.trasporto_salma == 1 ? scheda.trasporto_salma_a : ""), x_right + 5, 49);

        doc.rect(x_right, 56, 3, 4);
        if (scheda?.trasporto_cadavere == 1) { doc.text(`x`, x_right + 0.5, 59) }
        doc.text(`Trasporto cadavere`, x_right + 5, 59);
        doc.text(`da ` + (scheda?.trasporto_cadavere == 1 ? scheda.trasporto_cadavere_da : ""), x_right + 5, 66);
        doc.text(`a ` + (scheda?.trasporto_cadavere == 1 ? scheda.trasporto_cadavere_a : ""), x_right + 5, 74);

        doc.rect(x_right, 81, 3, 4);
        if (scheda?.data_suggello) { doc.text(`x`, x_right + 0.5, 84) }

        doc.text(`Sugello in ` + (scheda?.data_suggello ? scheda?.suggello_in : ""), x_right + 5, 84);
        doc.text(`il giorno ` + (scheda?.data_suggello ? convertDate(scheda.data_suggello) : ""), x_right + 5, 93);
        doc.text(`alle ore ` + (scheda?.data_suggello ? scheda.orario_suggello : ""), x_right + 70, 93);
        doc.rect(x_right, 100, 3, 4);
        if (scheda?.data_funerale) { doc.text(`x`, x_right + 0.5, 103) }
        doc.text(`Funerale in ` + (scheda?.data_funerale ? scheda?.funerale_in : ""), x_right + 5, 103);
        doc.text(`il giorno ` + (scheda?.data_funerale ? convertDate(scheda.data_funerale) : ""), x_right + 5, 110);
        doc.text(`alle ore ` + (scheda?.data_funerale ? scheda.orario_funerale : ""), x_right + 70, 110);

        doc.rect(x_right, 117, 3, 4);
        if (scheda?.parrocchia == 1) { doc.text(`x`, x_right + 0.5, 120) }
        doc.text(`Parocchia di ` + (scheda?.parrocchia == 1 ? scheda?.luogo_parrocchia : ""), x_right + 5, 120);

        doc.rect(x_right, 127, 3, 4);
        if (scheda?.disposizione == 1) { doc.text(`x`, x_right + 0.5, 130) }
        doc.text(`Cremazione forno crematorio di ` + (scheda?.disposizione == 1 ? scheda.forno_crematorio : ""), x_right + 5, 130);

        doc.rect(x_right, 137, 3, 4);
        if (scheda?.disposizione == 2 || scheda.cimitero != "" || scheda.cimitero !== null) { doc.text(`x`, x_right + 0.5, 140) }
        doc.text(`Cimitero di ` + (scheda?.disposizione == 2 || scheda.cimitero != "" || scheda.cimitero !== null ? scheda.cimitero : ""), x_right + 5, 140);

        doc.text(`Sepoltura ` + (scheda?.disposizione == 2 || scheda.cimitero != "" || scheda.cimitero !== null ? scheda.sepoltura : ""), x_right + 5, 147);

        doc.rect(x_right, 154, 3, 4);
        doc.text(`Altro`, x_right + 5, 157);

        doc.text(`Il ` + convertDate(new Date()), x_right + 5, 190);
        doc.text(`FIRMA per accettazione`, x_right + 70, 190);
        doc.line(x_right + 60, 200, x_right + 120, 200);

        // Impostazioni del font
        // Salva il PDF
        doc.line(doc.internal.pageSize.getWidth() / 2, 4, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 4);

        doc.output('pdfobjectnewwindow', 'scheda.pdf');
    };

    const handleStampaSchedaDati = (e) => {
        e.preventDefault();
        if (operazione === "inserimento") {
            alert("Attenzione, per stampare devi prima salvare la scheda")
            return;
        }
        handleSave();
        const doc = new jsPDF();

        const currentDate = new Date().toLocaleDateString('it-IT');
        doc.setFontSize(14);
        doc.addImage(box, 'PNG', doc.internal.pageSize.getWidth()/2 -20, 3, 40, 20);
        doc.setFont(undefined, 'bold')
        doc.text(`Cognome:`, 10, 34);
        doc.setFont(undefined, 'normal')
        doc.text(defunto?.cognome, 40, 34);
        doc.setFont(undefined, 'bold')
        doc.text(`Nome:`, 130, 34);
        doc.setFont(undefined, 'normal')
        doc.text(defunto?.nome, 150, 34);

        doc.setFont(undefined, 'bold')
        doc.text(`Fattura numero:`, 10, 44);
        doc.setFont(undefined, 'normal')
        doc.text(scheda?.numero_fattura, 50, 44);
        doc.setFont(undefined, 'bold')
        doc.text(`Del:`, 130, 44);
        doc.setFont(undefined, 'normal')
        doc.text(convertDate(scheda?.data_fattura), 145, 44);

        doc.setFont(undefined, 'bold')
        doc.text(`Telefono principale:`, 10, 54);
        doc.setFont(undefined, 'normal')
        doc.text(richiedente?.telefono.toString(), 60, 54);
        doc.setFont(undefined, 'bold')
        doc.text(`Telefono:`, 130, 54);
        doc.setFont(undefined, 'normal')
        doc.text(scheda?.telefono.toString(), 155, 54);

        doc.setFont(undefined, 'bold')
        doc.text(`Acconto:`, 10, 64);
        doc.setFont(undefined, 'normal')
        doc.text(scheda?.acconto.toString(), 40, 64);
        doc.setFont(undefined, 'bold')
        doc.text(`Saldo a pagare`, 130, 64);
        doc.setFont(undefined, 'normal')
        doc.text(scheda?.saldo_a_pagare.toString(), 170, 64);

        doc.setFont(undefined, 'bold')
        doc.text(`Pagato:`, 10, 74);
        doc.setFont(undefined, 'normal')
        doc.text(scheda?.pagato ==1?"Si":"No", 40, 74);
        doc.setFont(undefined, 'bold')
    
      
        
          
         
          
 





        doc.output('pdfobjectnewwindow', 'scheda.pdf');
    };

    const handleStampaTrasporto = async (e) => {
        e.preventDefault();
        if (operazione === "inserimento") {
            alert("Attenzione, per stampare devi prima salvare la scheda");
            return;
        }

        let trasporto_temp = { ...trasporto };
        trasporto.data_stampa = new Date();
        await setTrasporto(trasporto_temp);

        const doc = new jsPDF();
        const currentDate = new Date().toLocaleDateString('it-IT');

        let currentLuogo = luoghi_trasporto.filter(el => el.id == trasporto.luogo_trasporto)[0]
        doc.setFontSize(10);

        doc.setFont(undefined, 'bold')
        doc.text(`Spett.le Egr Sig.Sindaco del Comune di Milano`, 150, 10, { align: 'right' });
        doc.setFont(undefined, 'normal')
        doc.setFontSize(11)
        doc.text(doc.splitTextToSize(`Il sottoscritto Belloni Giuseppe, nato a Lodi (LO) il 20.06.1941, residente a Vizzolo P. (MI) in via P. Nenni n° 2, in qualità di titolare e direttore tecnico dell'Impresa Funebre BELLONI GIUSEPPE, con sede a Melegnano (MI) in via G. Dezza n° 47, aut. Reg.Lomb. n° 2 del 25/02/2007 rilasciata da Comune di Melegnano (MI), partita iva 0° 03191850159, tel. 02.9831945, per delega e mandato del famigliare avente diritto, inoltra istanza alla S.V. per il rilascio delle Autorizzazioni al:`, doc.internal.pageSize.getWidth() - 25), 10, 20);
        // Impostazioni del font
        doc.setFont(undefined, 'bold')
        doc.rect(10, 42, 3, 4);
        if (scheda?.disposizione == 2) { doc.text(`x`, 10.5, 45) }
        doc.text(`Trasporto e sepoltura/tumulazione`, 15, 45);
        doc.rect(10, 48, 3, 4);
        if (scheda?.disposizione == 1) { doc.text(`x`, 10.5, 51) }

        doc.text(`Trasporto, cremazione, tumulazione/affido/dispersione delle risultanti ceneri`, 15, 51);
        doc.text(`del cadavere di:`, (doc.internal.pageSize.getWidth() / 2) - 5, 58, { align: "center" });
        doc.setFont(undefined, 'normal')

        doc.text(`Cognome/Nome: ${defunto.nome + " " + defunto.cognome}`, 10, 68);
        doc.text(`Nato a: ` + defunto.luogo_nascita, 10, 78);
        doc.text(`Il: ` + convertDate(defunto.data_nascita), 110, 78);
        doc.text(`Deceduto a: ${defunto.luogo_decesso}`, 10, 88);
        doc.text(`Il: ` + convertDate(defunto.data_decesso), 110, 88);
        doc.text(`Presso: Abitazione`, 10, 98);
        doc.text(`Codice Fiscale: ` + defunto.codice_fiscale, 10, 108);
        doc.setFont(undefined, 'bold')
        doc.text(`dal luogo di decesso in questo comune al luogo di:`, 10, 118);
        doc.setFont(undefined, 'normal')
        let curr_height = 118;
        doc.setFontSize(10)
        doc.rect(10, 123, 3, 4);
        if (scheda?.trasporto_salma == 1) { doc.text(`x`, 10.5, 126) }
        doc.text(doc.splitTextToSize("Prosecuzione periodo osservazione ai sensi della L. Reg. Lomb. 4/2019 e del Reg 6/2004 Reg. Lomb. e succ. mod e int.presso: abitazione/Ospedale/RSA/Casa funeraria nel Comune di: " + (scheda?.trasporto_salma == 1 ? scheda?.trasporto_salma_a : ""), doc.internal.pageSize.getWidth() - 20), 15, 126);
        doc.setFontSize(10)
        doc.rect(10, 134, 3, 4);
        if (scheda.data_funerale) { doc.text(`x`, 10.5, 137) }
        doc.text("Funerale nel Comune di: " + (scheda.data_funerale ? scheda?.funerale_in : ""), 15, 137);
        doc.text("Con sosta presso " + (scheda.data_funerale ? trasporto?.sosta : ""), 15, 145);
        doc.rect(10, 150, 3, 4);
        if (scheda?.disposizione == 2 && (scheda?.tipologia_affidamento == 3 || scheda?.tipologia_affidamento == 4)) { doc.text(`x`, 10.5, 153) }
        doc.text("Inumazione/tumulazione Cimitero del Comune di: " + ((scheda?.disposizione == 2 && (scheda?.tipologia_affidamento == 3 || scheda?.tipologia_affidamento == 4)) ? scheda?.trasporto_salma_a : ""), 15, 153);
        doc.rect(10, 158, 3, 4);
        if (scheda?.disposizione == 1) { doc.text(`x`, 10.5, 161) }
        doc.text("Cremazione presso il forno crematorio nel Comune di: " + (scheda?.disposizione == 1 ? scheda?.forno_crematorio : ""), 15, 161);
        doc.rect(10, 166, 3, 4);
        if (scheda?.disposizione == 1 && (scheda?.tipologia_affidamento == 3 || scheda?.tipologia_affidamento == 4)) { doc.text(`x`, 10.5, 169) }
        doc.text("Tumulazione delle risultanti ceneri nel Cimitero del Comune di: " + ((scheda?.tipologia_affidamento == 3 || scheda?.tipologia_affidamento == 4) ? scheda?.cimitero + " " + scheda?.sepoltura : ""), 15, 169);
        doc.rect(10, 174, 3, 4);
        if (scheda?.tipologia_affidamento == 1) { doc.text(`x`, 10.5, 177) }
        doc.text("Affido delle risultanti ceneri al famigliare avente diritto.", 15, 177);
        doc.rect(10, 182, 3, 4);
        if (scheda?.tipologia_affidamento == 2) { doc.text(`x`, 10.5, 185) }
        doc.text("Dispersione delle risultanti ceneri al famigliare avente diritto.", 15, 185);
  
        doc.setFont(undefined, 'bold')
        doc.text(`Il trasporto del cadavere avrà luogo il giorno ${convertDate(trasporto?.data_trasporto)} alle ore ${scheda?.orario_funerale}`, 10, 193);
        doc.setFontSize(8)
        doc.text(`Alla presente si allega:`, 10, 200);
        doc.text(`- Delega/mandato del famigliare avente diritto:`, 10, 205);
        doc.text(doc.splitTextToSize(`- Documentazione per il rilascio delle autorizzazioni comunali connesse e consequenziali, ai sensi di DPR 285/90, Legge 130/2001, 
 Legge Regione Lombardia 4/2019 del Regolamento 6/2004 Regione Lombardia e successive modifiche e integrazioni`, doc.internal.pageSize.getWidth() - 25), 10, 210);
        doc.text(`- Valori bollati`, 10, 218);
        doc.text(`GIUSEPPE BELLONI`, 147, 235);

        // Salva il PDF
        doc.output('pdfobjectnewwindow', 'trasporto.pdf');
        //  handleSave();

    };

    const handleStampaDichiarazioneAtto = async (e) => {
        e.preventDefault();
        if (operazione === "inserimento") {
            alert("Attenzione, per stampare devi prima salvare la scheda");
            return;
        }
        let atto_temp = { ...atto };
        atto.data_stampa = new Date();
        await setAtto(atto_temp);

        const doc = new jsPDF();
        const currentDate = new Date().toLocaleDateString('it-IT');

        let currentLuogo = luoghi_trasporto.filter(el => el.id == trasporto.luogo_trasporto)[0]
        doc.setFontSize(10);

        doc.setFont(undefined, 'bold')
        doc.text(`DICHIARAZIONE SOSTITUTIVA DI ATTO DI NOTORIETA'`, 53, 10,);
        doc.setFont(undefined, 'normal')
        doc.setFontSize(11)
        doc.text(`(Rilasciata ai sensi dell'art. 47 del DPR n° 445 del 28/12/2000)`, 53, 15);
        doc.text(`Da produrre agli organi della Pubblica Amministrazione o al gestorial pubblici esercizi.`, 53, 20);

        doc.text(`A fini del rilascio dell'autorizzazione alla:`, 10, 30);

        // Impostazioni del font
        doc.setFont(undefined, 'bold')
        doc.rect(50, 35, 3, 4);
        doc.text(`x`, 50.5, 38);
        doc.text(` Cremazione`, 55, 38);
        doc.rect(95, 35, 3, 4);
        if (scheda?.tipologia_affidamento == 1) doc.text(`x`, 95.5, 38);
        doc.text(` Affido`, 100, 38);
        doc.rect(130, 35, 3, 4);
        if (scheda?.tipologia_affidamento == 2) doc.text(`x`, 130.5, 38);
        doc.text(` Dispersione`, 135, 38);
        doc.setFont(undefined, 'normal')

        doc.text(`Del defunto: ${defunto.nome + " " + defunto.cognome}`, 10, 48);
        doc.text(`Nato a: ` + defunto.luogo_nascita, 10, 55);
        doc.text(`Il: ` + convertDate(defunto.data_nascita), 110, 55);
        doc.text(`Deceduto a: ${defunto.luogo_decesso + " - " + defunto.via}`, 10, 62);
        doc.text(`Il: ` + convertDate(defunto.data_decesso), 110, 62);
        doc.text(`Residente a: ${defunto.luogo_residenza}`, 10, 69);
        doc.text(`in Via: ${defunto.via_residenza}`, 10, 76);
        doc.text(`Il sottoscritto: ${richiedente.nome + " " + richiedente.cognome}`, 10, 83);
        doc.text(`Nato a: ` + richiedente.luogo_nascita, 10, 90);
        doc.text(`Il: ` + convertDate(richiedente.data_nascita), 110, 90);
        doc.text(`Residente a: ${richiedente.citta_residenza}`, 10, 97);
        doc.text(`in Via/piazza: ${richiedente.via_residenza}`, 10, 103);
        doc.text(`In qualità di: ${richiedente.parentela}`, 10, 110);


        doc.text(doc.splitTextToSize(`consapevole delle sanzioni previste in caso di dichiarazione mendace, come stabiliti dall'art 76 del DPR 28/12/2000 n° 445 (Chiunque rilascia dichiarazioni mendaci, forma atti falsi o ne fa uso nei casi previsti dal presente testo unico è punito ai sensi del codice penale e delle leggi spaciali in materia) sotto la propria responsabilità:`, doc.internal.pageSize.getWidth() - 25), 10, 117);
        doc.setFont(undefined, 'bold')
        doc.text(`DICHIARA:`, 90, 138);
        doc.setFont(undefined, 'normal')
        doc.text(doc.splitTextToSize(`- di aver titolo ai sensi della Legge 30 marzo 2011 n° 130 art. 3, comma 1, della Legge Regionale n° 15/2019, de Regolamento della Regione Lombardia 9 novembre 2004 N°6 e s.m.i per la manifestazione delle volontà di cremazione/affido/dispersione in quanto parente più prossimo ai sensi dell'art. 74 e seguenti del codice civile del n° ${atto.numero_parenti} (parenti totali) dello stesso grado;`, doc.internal.pageSize.getWidth() - 25), 10, 145);
        doc.text(`- che il/la defunto/a di cui trattasi non ha espresso volontà contraria alla cremazione.`, 10, 165);
        doc.text(`- che il/la defunto/a sopra generalizzato ` + (atto.stimolatore_cardiaco == 1 ? "E'" : "NON E'") + " portatore di stimolatore cardiaco e/o similari.", 10, 172);

        doc.setFont(undefined, 'bold')
        doc.text(`In caso di affido:`, 10, 179);
        doc.setFont(undefined, 'normal')
        doc.rect(10, 184, 3, 4);
        if (scheda.tipologia_affidamento == 1) { doc.text(`x`, 10.5, 187) }
        doc.text(doc.splitTextToSize(`di esprimere volontà all'affidamento delle ceneri e di essere d'accordo che l'urna sia affidata a (cognome/nome) ${scheda.tipologia_affidamento == 1 ? scheda.nome_affidatario : "            "} in qualità di: ${scheda.tipologia_affidamento == 1 ? scheda.qualita_affidatario : "            "} in luogo concordato.`, doc.internal.pageSize.getWidth() - 25), 15, 187);


        doc.setFont(undefined, 'bold')
        doc.text(`In caso di dispersione:`, 10, 197);
        doc.setFont(undefined, 'normal')
        doc.rect(10, 202, 3, 4);
        if (scheda.tipologia_affidamento == 2) { doc.text(`x`, 10.5, 205) }
        doc.text(doc.splitTextToSize(`che in vita il defunto ha espresso volontà orale di dispersione delle proprie ceneri che sarà effettuata da (cognome/nome) ${scheda.tipologia_affidamento == 2 ? scheda.nome_affidatario : "            "} in qualità di: ${scheda.tipologia_affidamento == 2 ? scheda.qualita_affidatario : "            "} in luogo concordato.`, doc.internal.pageSize.getWidth() - 25), 15, 205);




        doc.setFontSize(8)
        doc.setFont(undefined, 'bold')
        doc.text(`informativa ex art. 13 - 14 Regolamento europeo 2016/679 in materia di protezione dei dati`, 35, 220);
        doc.text(doc.splitTextToSize(`Dichiaro di essere informato/a, ai sensi e per gli effetti del'art 13 - 14 Regolamento europeo 2016/679 in materia di protezione dei dati che i dati personali raccolti saranno trattenti e potranno essere comunicati ai soggetti previsti per legge; anche con strumenti informatici, esclusivamente nell'ambito del procedimento per il quale la presente dichiarazione viene resa e che anche su di essi è possibile esercitare il diritto dii accesso ai senso dell'art. 15 del regolamento UE n. 679/2916`, doc.internal.pageSize.getWidth() - 25), 10, 227);
        doc.setFont(undefined, 'normal')
        doc.setFontSize(11)
        doc.text(`Luogo e data:`, 10, 245);
        doc.line(35, 245, 100, 245);
        doc.text(`Firma:`, 110, 245);
        doc.line(125, 245, 190, 245);
        doc.setFontSize(8);
        doc.text(`La presente dichiarazione sostitutiva di atto di notorietà deve essere accompagnata da fotocopia di documento di identità in corso di validità.
        La presente dichiarazione deve essere compilata e sosttoscritta singolarmente da almeno la metà più uno degli aventi titolo.
        `, 10, 255);

        // Salva il PDF
        doc.output('pdfobjectnewwindow', 'trasporto.pdf');
        //  handleSave();
    };

    const handleStampaRichiestaCremazionePavia = async (e) => {
        e.preventDefault();
        if (operazione === "inserimento") {
            alert("Attenzione, per stampare devi prima salvare la scheda");
            return;
        }


        const doc = new jsPDF();
        const currentDate = new Date().toLocaleDateString('it-IT');

        let currentLuogo = luoghi_trasporto.filter(el => el.id == trasporto.luogo_trasporto)[0]
        doc.setFontSize(10);

        doc.setFont(undefined, 'bold')
        doc.text(`Spett.le TEMPIO CREMATORIO PAVESE srl`, 123, 10,);
        doc.text(`PAVIA (PV)`, 135, 15,);
        doc.setFont(undefined, 'normal')
        doc.text(`Oggetto: Richiesta cremazione`, 55, 23,);
        doc.setFont(undefined, 'bold')
        doc.text(`CADAVERE - NON RESIDENTE`, 105, 23,);
        doc.setFont(undefined, 'normal')

        doc.text(`il/la sottoscritto/a:`, 10, 30,);
        doc.text(`Cognome/Nome:  ${richiedente.cognome} ${richiedente.nome}`, 10, 37,);
        doc.text(`nato/a:  ${richiedente.luogo_nascita}`, 10, 44,);
        doc.text(`il: ${convertDate(richiedente.data_nascita)}`, 110, 44,);
        doc.text(`Codice fiscale n°  ${richiedente.codice_fiscale}`, 10, 51,);
        doc.text(`in qualità di:  ${richiedente.parentela}`, 110, 51,);
        doc.text(`Residente a  ${richiedente.citta_residenza}`, 10, 58,);
        doc.text(`via/piazza   ${richiedente.via_residenza}`, 110, 58,);
        doc.setFont(undefined, 'bold')
        doc.text(`CHIEDE`, doc.internal.pageSize.getWidth()/2-10, 65,);
        doc.text(`La cremazione presso il forno crematorio di Pavia (PV) del CADAVERE DI:`, 40, 70,);
        doc.setFont(undefined, 'normal')
        doc.text(`Cognome/Nome: ${defunto.cognome + " " + defunto.nome}`, 10, 77,);
        doc.text(`Nato/a a: ${richiedente.luogo_nascita}`, 10, 84,);
        doc.text(`Il: ` + convertDate(defunto.data_nascita), 110, 84);
        doc.text(`Deceduto a: ${defunto.luogo_decesso + " - " + defunto.via}`, 10, 91,);
        doc.text(`Il: ` + convertDate(defunto.data_decesso), 110, 91);
        doc.text(`Codice fiscale n°  ${defunto.codice_fiscale}`, 10, 98,);
        doc.text(`Residente nel Comune di: ${defunto.luogo_residenza}`, 10, 105,);
        doc.setFont(undefined, 'bold')
        doc.text(`Le risultanti ceneri avranno la seguente destinazione finale:`, 45, 112,);
       
        doc.rect(10, 119, 3, 4);
        //METTERE LA X QUI
        if (scheda?.tipologia_affidamento == 3 || scheda?.tipologia_affidamento == 4) { doc.text(`x`, 10.5, 122) }
        doc.text(`Sepoltura`, 15, 122,);
        doc.setFont(undefined, 'normal')
        doc.text(`nel cimitero del Comune di ${scheda?.cimitero}`, 33, 122,);

        doc.setFont(undefined, 'bold')
        doc.rect(10, 126, 3, 4);
        //METTERE LA X QUI
        if (scheda?.tipologia_affidamento == 1) { doc.text(`x`, 10.5, 129) }
        doc.text(`Affido`, 15, 129,);
        doc.setFont(undefined, 'normal')
        doc.text(`al famigliare avente diritto:`, 27, 129,);
       
        doc.text(`Cognome  ${scheda?.tipologia_affidamento == 1?richiedente?.cognome:""}`, 15, 136,);
        doc.text(`Nome  ${scheda?.tipologia_affidamento == 1?richiedente?.nome:""}`, 90, 136,);

        doc.setFont(undefined, 'bold')
        doc.rect(10, 140, 3, 4);
        //METTERE LA X QUI
        if (scheda.tipologia_affidamento == 2) { doc.text(`x`, 10.5, 143) }
        doc.text(`Dispersione`, 15, 143,);
        doc.setFont(undefined, 'normal')
        doc.text(`da parte del famigliare avente diritto:`, 38, 143,);
        doc.text(`Cognome  ${scheda?.tipologia_affidamento == 2?richiedente?.cognome:""}`, 15, 150,);
        doc.text(`Nome  ${scheda?.tipologia_affidamento == 2?richiedente?.nome:""}`, 90, 150,);
        
        doc.setFont(undefined, 'bold')
        doc.text(`DICHIARA ai sensi del'art. 47 DPR 445/2000 e succ. modifiche e integrazioni`,40 ,157,);
        doc.text(`che il CADAVERE sopra generalizzato:`, 70, 164,);
        doc.setFont(undefined, 'normal')

        doc.setFont(undefined, 'bold')
        doc.rect(10, 171, 3, 4);
        //METTERE LA X QUI
        doc.text(`NON E'`, 15, 174,);
        doc.setFont(undefined, 'normal')
        doc.text(` portatore di PEACEMAKER impiantato il`, 28, 174,);
        doc.setFont(undefined, 'bold')
        doc.rect(10, 178, 3, 4);
        //METTERE LA X QUI
        doc.text(`E' PORTATORE`, 15, 181,);
        doc.setFont(undefined, 'normal')
        doc.text(`DI PACEMAKER impiantato il`, 42, 181,);
        doc.rect(10, 185, 3, 4);

        doc.text(`Protesi metalicche osse/Altro:`, 15, 188,);
     
    


        doc.setFontSize(8)
        doc.setFont(undefined, 'bold')
       doc.text(doc.splitTextToSize(`Si impegna al saldo del corrispettivo onere di cremazione, a mezzo bonifico bancario , nei confronti di " TEMPIO CREMATORIO PAVESE srl ", via Faede n°1, 25040 Esine (BS), P.IVA n° 04041200983, BPER Banca IBAN: I T 0 9 B 0 5 3 8 7 5 4 1 2 0 0 0 0 0 4 2 8 4 1 9 1 2`, doc.internal.pageSize.getWidth() - 25), 10, 200);
       
       doc.text(doc.splitTextToSize(`Delega l'impresa funebre "BELLONI GIUSEPPE" con sede a Melegnano (MI) via G.Dezza n° 47 all'eventuale ritiro dell'urna contenente le risultanti cenerei in caso di mia impossibilità`, doc.internal.pageSize.getWidth() - 25), 10, 210);
      
       doc.setFont(undefined, 'normal')
        doc.setFontSize(11)
        doc.text(`Luogo`, 10, 250);
        doc.line(23, 250, 70, 250);
        doc.text(`Data`, 72, 250);
        doc.line(82, 250, 100, 250);
        doc.text(`IN FEDE`, 150, 245);
        doc.line(125, 260, 190, 260);
        doc.setFontSize(8);


        // Salva il PDF
        doc.output('pdfobjectnewwindow', 'trasporto.pdf');
        //  handleSave();
    };

    const handleStampaDelegaMilano = async (e) => {
        e.preventDefault();
        if (operazione === "inserimento") {
            alert("Attenzione, per stampare devi prima salvare la scheda");
            return;
        }


        const doc = new jsPDF();
        const currentDate = new Date().toLocaleDateString('it-IT');

        let currentLuogo = luoghi_trasporto.filter(el => el.id == trasporto.luogo_trasporto)[0]
        doc.setFontSize(10);


        doc.setFont(undefined, 'normal')
        doc.text(`il sottoscritto:`, 10, 23,);
      
      

        doc.text(`Cognome  ${richiedente.cognome}`, 35, 23,);
        doc.text(`Nome  ${richiedente.nome}`, 35, 30,);
        doc.text(`in qualità di  ${richiedente.parentela}  del defunto sotto indicato`, 35, 37,);
        doc.text(`telefono  ${richiedente?.telefono}`, 35, 44,);
        doc.text(`nato/a  ${richiedente.luogo_nascita}`, 10, 51,);
        doc.text(`il  ${convertDate(richiedente.data_nascita)}`,170, 51,);
        doc.text(`residente a  ${richiedente.citta_residenza}`, 10, 58,);
        doc.text(`in via/piazza   ${richiedente.via_residenza}`, 10, 65,);
        doc.text(`n° ${richiedente.civico_residenza}`,170, 65,);
        doc.text(`carta identità/altro n° ${richiedente.numero_documento}`, 10, 72,);
        doc.text(`rilasciata/o il  ${convertDate(richiedente.data_rilascio_documento)}`, 10, 79,);
        doc.text(`Codice fiscale n°  ${richiedente.codice_fiscale}`, 10, 86,);

        doc.text(`dichiara sotto la propria resposnabilità che le generalità del defunto/a sono:`, 50, 93,);

        doc.text(`Cognome  ${defunto.cognome}`, 10, 100,);
        doc.text(`Nome  ${defunto.nome}`, 10, 107,);
        doc.text(`nato/a  ${defunto.luogo_nascita}`, 10, 114,);
        doc.text(`il  ${convertDate(defunto.data_nascita)}`,170, 114,);
        doc.text(`residente a  ${defunto.luogo_residenza}`, 10, 121,);
        doc.text(`in via/piazza   ${defunto.via_residenza}`, 10, 128,);
        doc.text(`n°  ${defunto.civico_residenza}`,170, 128,);
        doc.text(`paternità ${defunto.genitore_1}`, 10, 135,);
        doc.text(`maternità ${defunto.genitore_2}`, 10, 142,);
        doc.text(`stato civile`, 10, 149,);
        doc.rect(30, 146, 3, 4);
        doc.text(defunto?.stato_civile, 35, 149,);
        doc.rect(30, 153, 3, 4);
        doc.text(`coniugato/a con  ${defunto.coniuge}`, 35, 156,);
        doc.text(`nato/a  ${defunto.luogo_coniuge}`, 35, 163,);
        doc.text(`il  ${convertDate(defunto?.data_nascita_coniuge)}`,150, 163,);
        doc.text(`matrimonio contratto a  ${defunto.luogo_atto_coniuge}`, 35, 170,);
        doc.text(`il  ${convertDate(defunto.data_coniuge)}`,150, 170,);


        doc.rect(30, 190, 3, 4);
        doc.text(`vedovo/a di  ${defunto.coniuge}`, 35, 193,);
        doc.text(`nato/a  ${defunto.luogo_coniuge}`, 35, 200,);
        doc.text(`il  ${convertDate(defunto.data_nascita_coniuge)}`,100, 200,);
        doc.text(`deceduto/a a  `, 35, 207,);
        doc.text(`il  ${convertDate(defunto.data_decesso_coniuge)}`,150, 207,);
        doc.text(`matrimonio contratto a  ${defunto.luogo_atto_coniuge}`, 35, 214,);
        doc.text(`il  ${convertDate(defunto.data_coniuge)}`,150, 214,);

       
      
       doc.setFont(undefined, 'normal')
        doc.setFontSize(11)
        doc.text(`Il`, 10, 250);
        doc.line(23, 250, 70, 250);
        doc.text(`(..............)`, 72, 250);
        doc.line(82, 250, 100, 250);
        doc.text(`IN FEDE`, 150, 245);
        doc.line(125, 260, 190, 260);
        doc.setFontSize(8);


        // Salva il PDF
        doc.output('pdfobjectnewwindow', 'trasporto.pdf');
        //  handleSave();
    };
  
     

    const handleStampaAffidamentoCeneri = async (e) => {
        e.preventDefault();
        if (operazione === "inserimento") {
            alert("Attenzione, per stampare devi prima salvare la scheda");
            return;
        }


        const doc = new jsPDF();
        const currentDate = new Date().toLocaleDateString('it-IT');

        let currentLuogo = luoghi_trasporto.filter(el => el.id == trasporto.luogo_trasporto)[0]
        doc.setFontSize(12);

        doc.setFont(undefined, 'bold')
        doc.text(`AFFIDAMENTO DELLE CENERI (1)`, 70, 10,);
        doc.text(`(Art. 8 della Legge Regionale 22/03 e Art. 14 comma 6 regolamento regionale n. 6/04)`, 10, 17,);
        doc.setFont(undefined, 'normal')
        doc.setFontSize(12);

        doc.text(`il/la sottoscritto/a:  ${richiedente.cognome} ${richiedente.nome}`, 10, 22,);
        doc.text(`nato/a:  ${richiedente.luogo_nascita}`, 10, 27,);
        doc.text(`il: ${convertDate(richiedente.data_nascita)}`, 110, 27,);

        doc.text(`e residente a  ${richiedente.citta_residenza}`, 10, 32,);
        doc.text(`in via ${richiedente.via_residenza}`, 110, 37,);
        doc.text(`in qualità di ${richiedente.parentela}`, 110, 42,);
        doc.text(`(indicare il grado di parentela o la condizione familiare)`, 70, 47,);
        doc.setFont(undefined, 'bold')
        doc.text(`DICHIARA CHE CONSERVERÀ LE CENERI`, doc.internal.pageSize.getWidth()/2-20, 57,);
        doc.setFont(undefined, 'normal')
        doc.text(`del defunto/a: ${defunto.cognome + " " + defunto.nome}`, 10, 62,);
        doc.text(`nato/a a: ${richiedente.luogo_nascita}`, 10, 67,);
        doc.text(`il ` + convertDate(defunto.data_nascita), 110, 67);
        doc.text(`deceduto a: ${defunto.luogo_decesso + " - " + defunto.via}`, 10, 72,);
        doc.text(`il ` + convertDate(defunto.data_decesso), 110, 72);
        doc.text(`codice fiscale   ${defunto.codice_fiscale}`, 10, 77,);
        doc.text(`presso la propria abitazione,`, 110, 77,);
        doc.text(`luogo di residenza legale, sita in ${defunto.luogo_residenza}`, 10, 82,);
        doc.text(`in via/piazza ${defunto.via_residenza}`, 10, 87,);
        doc.text(`sotto la propria diligente`, 115, 87,);
        doc.text(`custodia, garantendono la non profanazione.`, 10, 92,);
        doc.setFont(undefined, 'bold')
        doc.text(`Dichiara altresi:`, 10, 102,);
        doc.setFontSize(10)
        doc.text(`-`, 10, 107,);
        doc.text(doc.splitTextToSize(`di essere consapevole che costituisce reato la dispersione delle ceneri non autorizzata dall'Ufficiale di Stato Civile del Comune ove è avvenuto il decesso o effettuata con modalità diverse rispetto a quanto indicato dal defunto nonchè l'abbandono dell'urna;`, doc.internal.pageSize.getWidth() - 25), 20, 107);
        doc.text(`-`, 10, 130,);
        doc.text(doc.splitTextToSize(`di conservare l'urna in luogo confinato e stabile, protetta da possibili asportazioni, aperture o rotture accidentali.`, doc.internal.pageSize.getWidth() - 25), 20, 130);
        doc.text(`-`, 10, 142,);
        doc.text(doc.splitTextToSize(`di essere stato informato che, nel caso intenda recedere dall'affidamento delle ceneri, è tenuto a conferirle al cinerario comune o provvedere alla loro tumulazione in cimitero.`, doc.internal.pageSize.getWidth() - 25), 20, 142);
       
   
       doc.setFont(undefined, 'normal')
        doc.setFontSize(12)
      
        doc.line(10, 152, 50, 152);
        doc.text(`il`, 51, 152);
        doc.line(54, 152, 74, 152);
        doc.text(`Firma`, 120, 152);
        doc.line(130, 152, 180, 152);
        doc.text(`(2)`, 185, 152);
  
        doc.setFont(undefined, 'bold');
        doc.text(`== da compilarsi preventivamente alla consegna a cura del Comune ove è avvenuto il decesso ==`, 10, 157);
        doc.setFont(undefined, 'normal');
        doc.text(`L'urna contenente le ceneri del defunto sopra indicato proviene da:`, 10, 162);
    
        doc.rect(10, 167, 3, 4);
        if (scheda?.disposizione == 1) { doc.text(`x`, 10.5, 170) }
        doc.text("cremazione eseguita nel crematorio di" + (scheda?.disposizione == 1 ? scheda?.forno_crematorio : ""), 15, 170);
        doc.text("in data:" + (scheda?.disposizione == 1 ? "inserire data cremazione": ""), 155, 170);
        doc.rect(10, 175, 3, 4);
        if (scheda?.disposizione == 1) { doc.text(`x`, 10.5, 178) }
        doc.text(`del cimitero ${(scheda?.disposizione == 1 ? "inserire cimitero" : "")} in CAPIRE COSA INSERIRE`, 15, 178);
        doc.rect(10, 181, 3, 4);
        doc.text("da luogo precedentemente autoizzato", 15, 184);
        doc.rect(10, 189, 3, 4);
        doc.text("dall'estero", 15, 192);
        doc.setFont(undefined, 'bold');
        doc.setFontSize(10)
        doc.text(doc.splitTextToSize(`Per l'affidamento dell'urna a familiare è stata verificata la presenza di espressa volontà del defunto o del coniuge o, in difetto di questi, dal parente più prossimo individuato secondo gli articoli 74, 75, 76 e 77 del codice civile o, nel caso di concorso di più parenti dello stesso grado, della maggioranza di essi, secondo documentazione, in originale o copia autenticata, allegata. Conseguentemente si autorizza il trasporto nel luogo sopra individuato`, doc.internal.pageSize.getWidth() - 20), 10, 200);
        doc.line(100, 230, 170, 230);
        doc.text(`(timbro e firma)`, 173, 230);
        
        // Salva il PDF
        doc.output('pdfobjectnewwindow', 'trasporto.pdf');
        //  handleSave();
    };
    
    const handleStampaDispersioneCeneri = async (e) => {
        e.preventDefault();
        if (operazione === "inserimento") {
            alert("Attenzione, per stampare devi prima salvare la scheda");
            return;
        }


        const doc = new jsPDF();
        const currentDate = new Date().toLocaleDateString('it-IT');

        let currentLuogo = luoghi_trasporto.filter(el => el.id == trasporto.luogo_trasporto)[0]
        doc.setFontSize(12);

        doc.setFont(undefined, 'bold')
        doc.text(`Modulo per la DISPERSIONE DELLE CENERI`, 70, 10,);
        doc.setFont(undefined, 'normal')
        doc.text(`(Art.73 Legge Regionale 33/2009 e s.m.i. e art. 13, comma 2 regolamento regionale n. 6/04 e s.m.i)`, 10, 17,);
        
        

        doc.text(`il/la sottoscritto/a Cognome/Nome:  ${richiedente.cognome} ${richiedente.nome}`, 10, 22,);
        doc.text(`data di nascita:  ${convertDate(richiedente.data_nascita)}`, 10, 27,);
        doc.text(`luogo di nascita ${richiedente.luogo_nascita}`, 110, 27,);

        doc.text(` residente a  ${richiedente.citta_residenza}`, 10, 32,);
        doc.text(`Via ${richiedente.via_residenza}`, 110, 32,);
        doc.text(`n. ${richiedente.civico_residenza}`, 190, 32,);
        doc.text(`in qualità di (1) ${richiedente.parentela}`, 10, 37,);
        doc.setFont(undefined, 'bold')
        doc.text(doc.splitTextToSize(`Consapevole di quanto specificato agli articoli 2 e 3 della legge 30 marzo 2001, n. 130 e ai relativi provvedimenti di esecuzione, in ordine ai luoghi e alle modalità di possibile dispersione, nonché del contenuto dell'articolo 411 del codice penale`, doc.internal.pageSize.getWidth() - 25), 10, 42)
        doc.setFont(undefined, 'normal')
        doc.text(doc.splitTextToSize(`DICHIARA CHE, SECONDO L'ESPRESSA VOLONTA' DEL DEFUNTO, EFFETTUERA' LA DISPERSIONE DELLE CENERI DI`, doc.internal.pageSize.getWidth() - 25), 10, 62)
       
        doc.text(`Cognome/Nome: ${defunto.cognome + " " + defunto.nome}`, 10, 77,);
        doc.text(`data di nascita: ${convertDate(defunto.data_nascita)}`, 110, 82);
        doc.text(`luogo di nascita ${richiedente.luogo_nascita}`, 10, 82,);
      
        doc.text(`decesso avvenuto nel comune di ${defunto.luogo_decesso}`, 10, 87,);
        doc.text(`il ` + convertDate(defunto.data_decesso), 110, 87);
        doc.text(`in vita residente a ${defunto.luogo_residenza}`, 10, 92,);
        doc.text(`Via ${defunto.via_residenza}`, 110, 92,);
        doc.text(`n. ${defunto.civico_residenza}`, 190, 92,);
        doc.text(`Codice Fiscale ${defunto.codice_fiscale}`, 10, 97,);
        
        
        
        doc.setFontSize(10);
        doc.text(`a)`, 15, 100,);
        doc.rect(20, 97, 3, 4);
        doc.text(`nel cimitero di`, 25, 100,);
        doc.text(`nel`, 110, 100,);
        doc.rect(117, 97, 3, 4);
        doc.text(`Giardino delle rimembranze`, 123, 100,);
        doc.rect(175, 97, 3, 4);
        doc.text(`Cinerario comune`, 180, 100,);
        doc.text(`b)`, 15, 105,);
        doc.rect(20, 102, 3, 4);
        doc.text(`In area privata fuori dai centri abitati sita in`, 25, 105,);
        doc.text(`c)`, 15, 110,);
        doc.rect(20, 107, 3, 4);
        doc.text(`in natura e specificatamente: `, 25, 110,);
   
        doc.rect(72, 107, 3, 4);
        doc.text(`mare`, 77, 110,);

        doc.rect(87, 107, 3, 4);
        doc.text(`lago`, 92, 110,);


        doc.rect(101, 107, 3, 4);
        doc.text(`fiume`, 106, 110,);

        doc.rect(117, 107, 3, 4);
        doc.text(`aria`, 122, 110,);
        doc.text(`Dichiara altresì che provvederà ad effettuare la dispersione entro 30 giorni dal ritiro dell'urna.`, 10, 127,);
        doc.setFont(undefined, 'bold');

        doc.text(`La manifestazione della volontà del defunto che le sue ceneri siano disperse risulta da:`, 10, 132,);
       
        doc.setFont(undefined, 'normal');
        doc.text(`a)`, 15, 137,);
        doc.rect(20, 134, 3, 4);
        doc.text(`disposizione testamentaria del defunto;`, 25, 137,);

        doc.text(`b)`, 15, 142,);
        doc.rect(20, 139, 3, 4);
        doc.text(doc.splitTextToSize(`volontà espressa del defunto, iscritto ad associazione avente tra i propri fini quello della cremazione, che le proprie ceneri vengano disperse.`, doc.internal.pageSize.getWidth() - 20), 25, 142,);
        doc.text(`c)`, 15, 152,);
        doc.rect(20, 149, 3, 4);
        doc.text(`processo verbale degli aventi diritto`, 25, 152,);

        doc.text(doc.splitTextToSize(`Dichiara altresì di essere consapevole che costituisce reato la dispersione delle ceneri non autorizza dall'Ufficiale di Stato Civile del Comune ove è avvenuto il decesso o effettuata con modalità diverse rispetto a quanto indicato dal defunto.`, doc.internal.pageSize.getWidth() - 20), 10, 160,);
        doc.text(`Firma`, 10, 175);
        doc.line(20, 175, 100, 175);
        
        doc.setFont(undefined, 'bold');
        doc.text(`================= (da compilarsi a cura del Comune ove è avvenuto il decesso) ===============`, 10, 185,);
        doc.setFont(undefined, 'normal');

        doc.text(`La dispersione delle ceneri del defunto`, 10, 190);
        doc.text(`nato il`, 10, 195);
        doc.text(`a`, 70, 195);
        doc.text(`e deceduto il`, 10, 200);
        doc.text(`a`, 80, 200);
        doc.text(`è stata autorizzata con atto n.`, 10, 205);
        doc.text(`rilasciato il`, 120, 205);
    
      
        doc.line(120, 215, 180, 215);
        doc.text(`(timbro e firma)`, 140, 222);


        /*
        Note per la compilazione
        1. L'incarico della dispersione può essere desumibile dall'espressa volontà del defunto o, in carenza, la dispersione
        è eseguita dal coniuge, altro famigliare, esecutore testamentario, rappresentante legale dell'associazione cui risultava iscritto il defunto o da personale autorizzato dall'avente diritto.

        2. Il luogo della dispersione, ove stabilito dal defunto, è scelto dall'avente diritto.

        Mod. dichiar.dispersione ceneri del 8/1/2020
        */

        /*
        doc.text(`== da compilarsi preventivamente alla consegna a cura del Comune ove è avvenuto il decesso ==`, 10, 157);
        doc.setFont(undefined, 'normal');
        doc.text(`L'urna contenente le ceneri del defunto sopra indicato proviene da:`, 10, 162);
    
        doc.rect(10, 167, 3, 4);
        if (scheda?.disposizione == 1) { doc.text(`x`, 10.5, 170) }
        doc.text("cremazione eseguita nel crematorio di" + (scheda?.disposizione == 1 ? scheda?.forno_crematorio : ""), 15, 170);
        doc.text("in data:" + (scheda?.disposizione == 1 ? "inserire data cremazione": ""), 155, 170);
        doc.rect(10, 175, 3, 4);
        if (scheda?.disposizione == 1) { doc.text(`x`, 10.5, 178) }
        doc.text(`del cimitero ${(scheda?.disposizione == 1 ? "inserire cimitero" : "")} in CAPIRE COSA INSERIRE`, 15, 178);
        doc.rect(10, 181, 3, 4);
        doc.text("da luogo precedentemente autoizzato", 15, 184);
        doc.rect(10, 189, 3, 4);
        doc.text("dall'estero", 15, 192);
        doc.setFont(undefined, 'bold');
        doc.setFontSize(10)
        doc.text(doc.splitTextToSize(`Per l'affidamento dell'urna a familiare è stata verificata la presenza di espressa volontà del defunto o del coniuge o, in difetto di questi, dal parente più prossimo individuato secondo gli articoli 74, 75, 76 e 77 del codice civile o, nel caso di concorso di più parenti dello stesso grado, della maggioranza di essi, secondo documentazione, in originale o copia autenticata, allegata. Conseguentemente si autorizza il trasporto nel luogo sopra individuato`, doc.internal.pageSize.getWidth() - 20), 10, 200);
        doc.line(100, 230, 170, 230);
        doc.text(`(timbro e firma)`, 173, 230);
        */
        // Salva il PDF
        doc.output('pdfobjectnewwindow', 'trasporto.pdf');
        //  handleSave();
    };
//#endregion


    //#region Handle vari per modifiche ai campi
    const handleChangeScheda = (e) => {

        let el = { ...scheda };
        el[e.target.name] = capitalizeFirstLetter(e.target.value);
        if (e.target.name == "data_funerale") {
            el.data_suggello = capitalizeFirstLetter(e.target.value);
        }
        if (e.target.name == "orario_funerale") {
            el.orario_suggello = capitalizeFirstLetter(e.target.value);
        }
        if (e.target.name == "funerale_in") {
            el.suggello_in = capitalizeFirstLetter(e.target.value);
        }
        setScheda(el);

    }
    const handleChangeSelectScheda = (e, name) => {

        let el = { ...scheda };
        el[name] = e.target.value;
        setScheda(el);

    }
    const handleChangeDataScheda = (e, name) => {

        let el = { ...scheda };
        el[name] = moment(e).format("YYYY-MM-DD");
        setScheda(el);
    }

    const handleChangeCheckbox = (e, name) => {

        let el = { ...scheda };
        el[name] = e.target.checked ? 1 : 0;
        setScheda(el);


    }


    const handleChangeDefunto = (e) => {

        let el = { ...defunto };
        el[e.target.name] = capitalizeFirstLetter(e.target.value);
        if (e.target.name == "data_nascita") {
            el.anni = calcYear(e.target.value)
        }

        if (e.target.name == "codice_fiscale" && e.target.value.length == 16) {
            try {
                let info = calcDataFromCf(e.target.value);
                el.luogo_nascita = info.birthPlace;
                el.data_nascita = info.birthDate;
                el.anni = calcYear(moment(info.birthDate))
            } catch {
                alert("ATTENZIONE, Codice fiscale non valido")
            }
        }
        setDefunto(el);
    }
    const handleChangeDataDefunto = (e, name) => {

        let el = { ...defunto };
        el[name] = moment(e).format("YYYY-MM-DD");
        if (name == "data_nascita") {
            el.anni = calcYear(e)
        }
        setDefunto(el);
    }
    const handleChangeRichiedente = (e) => {
        let el = { ...richiedente };
        el[e.target.name] = capitalizeFirstLetter(e.target.value);
        console.log(e.target.value.length)
        if (e.target.name == "codice_fiscale" && e.target.value.length == 16) {
            try {
                let info = calcDataFromCf(e.target.value);
                el.luogo_nascita = info.birthPlace;
                el.data_nascita = info.birthDate;
            } catch {
                alert("ATTENZIONE, Codice fiscale non valido")
            }
        }


        setRichiedente(el);
    }

    const handleChangeDataRichiedente = (e, name) => {
        console.log(e, name)
        let el = { ...richiedente };
        el[name] = moment(e).format("YYYY-MM-DD");
        setRichiedente(el);
    }


    const handleOptionChange = (e) => {

        let el = { ...defunto };
        el.milano = e.target.value;
        if (e.target.value == 1) {
            el.luogo_decesso = "Milano"
        } else {
            el.luogo_decesso = ""
        }
        setDefunto(el);

    };


    const handleChangeTrasporto = (e) => {

        let el = { ...trasporto };
        el[e.target.name] = capitalizeFirstLetter(e.target.value);
        setTrasporto(el);

    }
    const handleChangeSelectTrasporto = (e, name) => {

        let el = { ...trasporto };
        el[name] = e.target.value;
        setTrasporto(el);

    }




    const handleChangeCheckboxAtto = (e, name) => {

        let el = { ...atto };
        el[name] = e.target.checked ? 1 : 0;
        setAtto(el);


    }


    const handleChangeAtto = (e) => {
        let el = { ...atto };
        el[e.target.name] = e.target.value;
        setAtto(el);
    }

    //#endregion Fine campi

    return (
        <>



            <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-row justify-between w-full">
                    <div>
                        <Button icon={<FiArrowLeft />} label="indietro" onClick={() => navigate("/")} />
                    </div>
                    <div className="flex items-center">

                        <Button label="Salva" icon={<FiSave />} onClick={() => handleSave()} />
                    </div>
                </div>


                <div>
                    <Accordion defaultExpanded className="bg-gray-100">
                        <AccordionSummary
                            expandIcon={<FiChevronDown />}
                            aria-controls="panel1-content"
                            id="panel1-header"

                        >
                            <h6 className="font-bold">Dati Scheda</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <form>
                                <div className="grid grid-cols-4 gap-2  bg-gray-100 ">

                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Cognome" type="text" name="cognome" value={defunto?.cognome} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Nome" type="text" name="nome" value={defunto?.nome} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>

                                    {/*} <div className="col-span-4 md:col-span-1">
                                        <Input label="Preventivo" type="text" name="preventivo" value={scheda?.preventivo} onChange={(e) => handleChangeScheda(e)} />

                                    </div> */}

                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Fattura numero" type="text" name="numero_fattura" value={scheda?.numero_fattura} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <p className="invisible">Pino</p>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                            <DatePicker name="data_fattura" label="Del" onChange={(e) => handleChangeDataScheda(e, "data_fattura")} slotProps={{ textField: { size: 'small', fullWidth: true, value: moment(scheda?.data_fattura) } }} />
                                        </LocalizationProvider>
                                        {/*   <Input label="Del" type="date" name="data_fattura" value={convertDateToInput(scheda?.data_fattura)} onChange={(e) => handleChangeScheda(e)} />
 */}


                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Telefono Principale" type="number" name="telefono" value={richiedente?.telefono} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Telefono" type="text" name="telefono" value={scheda?.telefono} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Acconto" type="text" name="acconto" value={scheda?.acconto} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Saldo a pagare" type="text" name="saldo_a_pagare" value={scheda?.saldo_a_pagare} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    {/*<div className="col-span-4 md:col-span-1">
                                        <Input label="Telefono" type="text" name="telefono2" value={scheda?.telefono2} onChange={(e) => handleChangeScheda(e)} />

                                    </div> */}


                                    <div className="col-span-4 md:col-span-1">
                                        <CustomCheckbox label="Pagato" value={scheda?.pagato} onClick={(e) => handleChangeCheckbox(e, "pagato")} />
                                    </div>
                                    {/*}   <div className="col-span-4 md:col-span-1">
                                            <CustomCheckbox label="Completato" value={scheda?.completato} onClick={(e) => handleChangeCheckbox(e, "completato")} />
                                        </div> */}
                                    <div className="col-span-4 md:col-span-1">
                                        <CustomCheckbox label="Archiviato" value={scheda?.archiviato} onClick={(e) => handleChangeCheckbox(e, "archiviato")} />
                                    </div>
                                    <div className="col-span-2"></div>
                                    <div className="col-span-4 flex flex-row-reverse gap-2">
                                        <div>      <Button icon={<FiPrinter />} onClick={(e) => handleStampaSchedaDati(e)} label="Stampa Scheda Dati" /></div>

                                    </div>

                                </div>

                            </form>
                        </AccordionDetails>
                    </Accordion>




                    <Accordion >
                        <AccordionSummary
                            expandIcon={<FiChevronDown />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6 className="font-bold">Anagrafica Richiedente</h6>
                        </AccordionSummary>
                        <AccordionDetails>

                            <form>
                                <div className="grid grid-cols-4 gap-2  p-2  bg-gray-100 ">

                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Cognome" type="text" name="cognome" value={richiedente?.cognome} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Nome" type="text" name="nome" value={richiedente?.nome} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>


                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Nato A" type="text" name="luogo_nascita" value={richiedente?.luogo_nascita} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <p className="invisible">Pino</p>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                            <DatePicker name="data_nascita" label="Data di nascita" onChange={(e) => handleChangeDataRichiedente(e, "data_nascita")} slotProps={{ textField: { size: 'small', fullWidth: true, value: moment(richiedente?.data_nascita) } }} />
                                        </LocalizationProvider>
                                        {/*<Input label="Data di Nascita" type="date" name="data_nascita" value={convertDateToInput(richiedente?.data_nascita)} onChange={(e) => handleChangeRichiedente(e)} /> */}

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Residente A" type="text" name="citta_residenza" value={richiedente?.citta_residenza} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Via" type="text" name="via_residenza" value={richiedente?.via_residenza} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="N°" type="text" name="civico_residenza" value={richiedente?.civico_residenza} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Codice Fiscale" type="text" name="codice_fiscale" value={richiedente?.codice_fiscale} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Documento D'identità" type="text" name="numero_documento" value={richiedente?.numero_documento} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">

                                        <p className="invisible">Pino</p>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                            <DatePicker name="data_rilascio_documento" label="Data Rilascio" onChange={(e) => handleChangeDataRichiedente(e, "data_rilascio_documento")} slotProps={{ textField: { size: 'small', fullWidth: true, value: moment(richiedente?.data_rilascio_documento) } }} />
                                        </LocalizationProvider>
                                        {/*  <Input label="Data Rilascio" type="date" name="data_rilascio_documento" value={convertDateToInput(richiedente?.data_rilascio_documento)} onChange={(e) => handleChangeRichiedente(e)} />*/}

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Luogo Rilascio documento" type="text" name="luogo_rilascio_documento" value={richiedente?.luogo_rilascio_documento} onChange={(e) => handleChangeRichiedente(e)} />
                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Parentela" type="text" name="parentela" value={richiedente?.parentela} onChange={(e) => handleChangeRichiedente(e)} />

                                    </div>
                                    <div className="col-span-2"></div>
                                    <div className="col-span-4 flex flex-row-reverse gap-2">
                                        <div>      <Button icon={<FiPrinter />} label="Carica documento" /></div>
                                        <div> <Button icon={<FiPrinter />} label="Stampa documento" /></div>
                                    </div>
                                </div>

                            </form>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion >
                        <AccordionSummary
                            expandIcon={<FiChevronDown />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6 className="font-bold"> Anagrafica Defunto</h6>
                        </AccordionSummary>
                        <AccordionDetails>

                            <form >
                                <div className="grid grid-cols-4 gap-2  p-2  bg-gray-100 ">


                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Cognome" type="text" name="cognome" value={defunto?.cognome} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Nome" type="text" name="nome" value={defunto?.nome} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Nato A" type="text" name="luogo_nascita" value={defunto?.luogo_nascita} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <div className="grid grid-cols-2 gap-2">
                                            <div className="col-span-1">
                                                <p className="invisible">Pino</p>
                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                                    <DatePicker name="data_nascita" label="Data di Nascita" onChange={(e) => handleChangeDataDefunto(e, "data_nascita")} slotProps={{ textField: { size: 'small', fullWidth: true, value: moment(defunto?.data_nascita) } }} />
                                                </LocalizationProvider>
                                                {/*     <Input label="Data di Nascita" type="date" name="data_nascita" value={convertDateToInput(defunto?.data_nascita)} onChange={(e) => handleChangeDefunto(e)} />*/}



                                            </div>
                                            <div className="col-span-1">
                                                <Input label="Anni" type="text" name="anni" value={defunto?.anni} onChange={(e) => handleChangeDefunto(e)} />
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-span-4 md:col-span-1">
                                        Comune decesso
                                        <div className="col-span-4 flex flex-row">
                                            <div>
                                                <label class="flex radio p-2 cursor-pointer">
                                                    <input class="my-auto transform scale-125" type="radio" checked={defunto?.milano == 1} value={1} name="comune" onChange={(e) => handleOptionChange(e)} />
                                                    <div class="title px-2">Milano</div>
                                                </label>
                                            </div>
                                            <div>
                                                <label class="flex radio p-2 cursor-pointer">
                                                    <input class="my-auto transform scale-125" type="radio" checked={defunto?.milano == 2} value={2} name="comune" onChange={(e) => handleOptionChange(e)} />
                                                    <div class="title px-2">Pavia</div>
                                                </label>
                                            </div>
                                            <div>
                                                <label class="flex radio p-2 cursor-pointer">
                                                    <input class="my-auto transform scale-125" value={0} type="radio" checked={defunto?.milano == 0} name="comune" onChange={(e) => handleOptionChange(e)} />
                                                    <div class="title px-2">Altro comune</div>
                                                </label>
                                            </div>
                                            <div>
                                                {defunto?.milano !== 0  ? "" : <Input type="text" name="luogo_decesso" value={defunto?.luogo_decesso} onChange={(e) => handleChangeDefunto(e)} />}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Via Decesso" type="text" name="via" value={defunto?.via} onChange={(e) => handleChangeDefunto(e)} />


                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <div className="grid grid-cols-2 gap-2">
                                            <div className="col-span-1">
                                                <p className="invisible">Pino</p>
                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                                    <DatePicker name="data_decesso" label="Data Decesso" onChange={(e) => handleChangeDataDefunto(e, "data_decesso")} slotProps={{ textField: { size: 'small', fullWidth: true, value: moment(defunto?.data_decesso) } }} />
                                                </LocalizationProvider>
                                                {/*       <Input label="Data decesso" type="date" name="data_decesso" value={convertDateToInput(defunto?.data_decesso)} onChange={(e) => handleChangeDefunto(e)} />*/}




                                            </div>
                                            <div className="col-span-1">
                                                <Input label="Orario Decesso" type="time" name="orario_decesso" value={defunto?.orario_decesso} onChange={(e) => handleChangeScheda(e)} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Codice Fiscale" type="text" name="codice_fiscale" value={defunto?.codice_fiscale} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>


                                    <div className="col-span-4 md:col-span-2">
                                        <Input label="Residente A " type="text" name="luogo_residenza" value={defunto?.luogo_residenza} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <div className="grid grid-cols-2 gap-2">
                                            <div className="col-span-1">
                                                <Input label="Via" type="text" name="via_residenza" value={defunto?.via_residenza} onChange={(e) => handleChangeDefunto(e)} />

                                            </div>
                                            <div className="col-span-1">
                                                <Input label="N°" type="text" name="civico_residenza" value={defunto?.civico_residenza} onChange={(e) => handleChangeDefunto(e)} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <Input label="Cittadinanza" type="text" name="cittadinanza" value={defunto?.cittadinanza} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <Input label="Genitore 1" type="text" name="genitore_1" value={defunto?.genitore_1} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <Input label="Genitore 2" type="text" name="genitore_2" value={defunto?.genitore_2} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <Input label="Stato Civile" type="text" name="stato_civile" value={defunto?.stato_civile} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <Input label="Coniugato con" type="text" name="coniuge" value={defunto?.coniuge} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <Input label="A" type="text" name="luogo_atto_coniuge" value={defunto?.luogo_atto_coniuge} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <p className="invisible">Pino</p>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                            <DatePicker name="data_coniuge" label="Il" onChange={(e) => handleChangeDataDefunto(e, "data_coniuge")} slotProps={{ textField: { size: 'small', fullWidth: true, value: moment(defunto?.data_coniuge) } }} />
                                        </LocalizationProvider>
                                        {/*     <Input label="Il" type="date" name="data_coniuge" value={convertDateToInput(defunto?.data_coniuge)} onChange={(e) => handleChangeDefunto(e)} />*/}




                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <Input label="Professione" type="text" name="professione" value={defunto?.professione} onChange={(e) => handleChangeDefunto(e)} />

                                    </div>
                                    <div className="col-span-4 flex flex-row-reverse gap-2">
                                        <div>      <Button icon={<FiPrinter />} label="Carica documento" /></div>
                                        <div> <Button icon={<FiPrinter />} label="Stampa documento" /></div>
                                    </div>

                                </div>
                            </form>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<FiChevronDown />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6 className="font-bold">Dati Funerale</h6>
                        </AccordionSummary>
                        <AccordionDetails>

                            <form >
                                <div className="grid grid-cols-4 gap-2  p-2  bg-gray-100 ">
                                    <div className="col-span-4 md:col-span-1">
                                        <div className="relative mb-2">
                                            <label className="text-gray-500 text-xs font-medium uppercase tracking-wider px-1 left-2 -top-2">
                                                Disposizione


                                            </label>
                                            <select className={`p-2 pl-2 w-full focus:outline-success-200  border-primary-300 border border-gray-300" `} value={scheda?.disposizione} onChange={(e) => handleChangeSelectScheda(e, "disposizione")}>
                                                <option value={0}>Seleziona...</option>
                                                <option value={1}>Cremazione</option>
                                                <option value={2}>Sepoltura</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="col-span-4 md:col-span-1">
                                        <div className="relative mb-2">
                                            <label className="text-gray-500 text-xs font-medium uppercase tracking-wider px-1 left-2 -top-2">
                                                Tipologia


                                            </label>
                                            <select className={`p-2 pl-2 w-full focus:outline-success-200  border-primary-300 border border-gray-300" `} value={scheda?.tipologia_affidamento} onChange={(e) => handleChangeSelectScheda(e, "tipologia_affidamento")}>
                                                <option value={0}>Seleziona...</option>
                                                {scheda?.disposizione == 1 ?
                                                    <>
                                                        <option value={1}>Affido</option>
                                                        <option value={2}>Dispersione</option>
                                                    </>

                                                    : ""
                                                }
                                                <option value={3}>Tumulazione</option>
                                                <option value={4}>Inumazione</option>
                                            </select>
                                        </div>

                                    </div>
                                    {scheda.disposizione == 1 ? <>
                                        <div className="col-span-4 md:col-span-1">
                                            <Input label={scheda?.tipologia_affidamento == 2 ? "Nominativo dispersore" : "Nominativo affidatario"} type="text" name="nome_affidatario" value={scheda?.nome_affidatario} onChange={(e) => handleChangeScheda(e)} />

                                        </div>  <div className="col-span-4 md:col-span-1">
                                            <Input label="In qualità di:" type="text" name="qualita_affidatario" value={scheda?.qualita_affidatario} onChange={(e) => handleChangeScheda(e)} />

                                        </div>


                                        <div className="col-span-4 md:col-span-1">
                                            <Input label="Forno crematorio" type="text" name="forno_crematorio" value={scheda?.forno_crematorio} onChange={(e) => handleChangeScheda(e)} />

                                        </div>
                                        <div className="col-span-4 md:col-span-1">
                                            <Input label="cimitero" type="text" name="cimitero" value={scheda?.cimitero} onChange={(e) => handleChangeScheda(e)} />

                                        </div>
                                        <div className="col-span-4 md:col-span-1">
                                            <Input label="tipologia" type="text" name="sepoltura" value={scheda?.sepoltura} onChange={(e) => handleChangeScheda(e)} />

                                        </div>
                                        <div className="col-span-1"></div>
                                    </> : <div className="col-span-2"></div>}
                                    {scheda.disposizione == 2 ? <>
                                        <div className="col-span-4 md:col-span-1">
                                            <Input label="cimitero" type="text" name="cimitero" value={scheda?.cimitero} onChange={(e) => handleChangeScheda(e)} />

                                        </div>
                                        <div className="col-span-4 md:col-span-1">
                                            <Input label="sepoltura" type="text" name="sepoltura" value={scheda?.sepoltura} onChange={(e) => handleChangeScheda(e)} />

                                        </div>
                                        <div className="col-span-2"></div>
                                    </> : ""}

                                    <div className="col-span-4 md:col-span-1">
                                        <p className="invisible">Pino</p>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                            <DatePicker name="data_funerale" label="Data Funerale" onChange={(e) => handleChangeDataScheda(e, "data_funerale")} slotProps={{ textField: { size: 'small', fullWidth: true, value: moment(scheda?.data_funerale) } }} />
                                        </LocalizationProvider>
                                        {/*    <Input label="Data Funerale" type="date" name="data_funerale" value={convertDateToInput(scheda?.data_funerale)} onChange={(e) => handleChangeScheda(e)} />*/}



                                    </div>
                                    <div className="col-span-1">
                                        <Input label="Orario Funerale" type="time" name="orario_funerale" value={scheda?.orario_funerale} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <div className="grid grid-cols-2 gap-2 ">
                                            <div className="col-span-1">
                                                <Input label="Luogo Funerale" type="text" name="funerale_in" value={scheda?.funerale_in} onChange={(e) => handleChangeScheda(e)} />
                                            </div>
                                            <div className="col-span-1">
                                                <Input label="Sosta" type="text" name="sosta" value={trasporto?.sosta} onChange={(e) => handleChangeTrasporto(e)} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-span-4 md:col-span-1">
                                        <p className="invisible">Pino</p>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
                                            <DatePicker name="data_suggello" label="Data Suggello" onChange={(e) => handleChangeDataScheda(e, "data_suggello")} slotProps={{ textField: { size: 'small', fullWidth: true, value: moment(scheda?.data_suggello) } }} />
                                        </LocalizationProvider>
                                        {/*    <Input label="Data Suggello" type="date" name="data_suggello" value={convertDateToInput(scheda?.data_suggello)} onChange={(e) => handleChangeScheda(e)} />*/}





                                    </div>
                                    <div className="col-span-1">
                                        <Input label="Orario suggello" type="time" name="orario_suggello" value={scheda?.orario_suggello} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <Input label="Luogo Suggello" type="text" name="suggello_in" value={scheda?.suggello_in} onChange={(e) => handleChangeScheda(e)} />

                                    </div>

                                    <div className="col-span-4 md:col-span-1">
                                        <CustomCheckbox label="Trasporto Salma" value={scheda?.trasporto_salma} onClick={(e) => handleChangeCheckbox(e, "trasporto_salma")} />
                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Trasporto Salma DA" type="text" name="trasporto_salma_da" value={scheda?.trasporto_salma_da} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Trasporto Salma A" type="text" name="trasporto_salma_a" value={scheda?.trasporto_salma_a} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-1"></div>

                                    <div className="col-span-4 md:col-span-1">
                                        <CustomCheckbox label="Trasporto Cadavere" value={scheda?.trasporto_cadavere} onClick={(e) => handleChangeCheckbox(e, "trasporto_cadavere")} />
                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Trasporto Cadavere DA" type="text" name="trasporto_cadavere_da" value={scheda?.trasporto_cadavere_da} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Trasporto Cadavere A" type="text" name="trasporto_cadavere_a" value={scheda?.trasporto_cadavere_a} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-1"></div>

                                    <div className="col-span-4 md:col-span-1">
                                        <CustomCheckbox label="Parrocchia" value={scheda?.parrocchia} onClick={(e) => handleChangeCheckbox(e, "parrocchia")} />
                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <Input label="Luogo parrocchia" type="text" name="luogo_parrocchia" value={scheda?.luogo_parrocchia} onChange={(e) => handleChangeScheda(e)} />

                                    </div>
                                    <div className="col-span-2"></div>
                                    <div className="col-span-4 flex flex-row-reverse gap-2">
                                        <div>      <Button icon={<FiPrinter />} label="Stampa Delega" onClick={(e) => handleStampa(e)} /></div>
                                        <div> <Button icon={<FiPrinter />} label="Stampa Trasporto" onClick={(e) => handleStampaTrasporto(e)} /></div>
                                      {defunto.milano==2 && scheda?.disposizione ==1 &&<div> <Button icon={<FiPrinter />} label="Stampa Richiesta cremazione (Pavia)" onClick={(e) => handleStampaRichiestaCremazionePavia(e)} /></div>}
                                      {defunto.milano==1 && <div> <Button icon={<FiPrinter />} label="Delega Milano" onClick={(e) => handleStampaDelegaMilano(e)} /></div>}
                                      {scheda?.disposizione ==1 &&<div> <Button icon={<FiPrinter />} label="Dispersione Ceneri" onClick={(e) => handleStampaDispersioneCeneri(e)} /></div>}
                                      { scheda?.disposizione ==1 &&<div> <Button icon={<FiPrinter />} label="Affidamento Ceneri" onClick={(e) => handleStampaAffidamentoCeneri(e)} /></div>}
                                    </div>








                                </div>
                            </form>
                        </AccordionDetails>
                    </Accordion>
                    <hr />
                    <div className="p-2">Documenti - {scheda.disposizione == 1 ? "Cremazione" : ""} {scheda.disposizione == 2 ? "Sepoltura" : ""} {defunto?.milano == 1 ? "- Milano" : ""}</div>
                    <Accordion className="hidden">
                        <AccordionSummary
                            expandIcon={<FiChevronDown />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6 className="font-bold"><div className="flex flex-row gap-2 justify-center items-center"><div><FaCircle className={trasporto?.data_stampa ? "text-green-900" : "text-red-700"} /></div><div>Istanza di trasporto</div></div></h6>
                        </AccordionSummary>
                        <AccordionDetails>

                            <div className="grid grid-cols-2 gap-2  bg-gray-100 ">
                                <div className="col-span-1">
                                    <Input label="Data trasporto" type="date" name="data_trasporto" value={convertDateToInput(trasporto?.data_trasporto)} onChange={(e) => handleChangeTrasporto(e)} />

                                </div>
                                <div className="col-span-1">
                                    <Input label="Orario trasporto" type="time" name="orario_trasporto" value={trasporto?.orario_trasporto} onChange={(e) => handleChangeTrasporto(e)} />

                                </div>
                                <div className="col-span-1">
                                    Luogo
                                    <select className={`p-2 pl-2 w-full focus:outline-success-200  border-primary-300 border border-gray-300" `} value={trasporto?.luogo_trasporto} onChange={(e) => handleChangeSelectTrasporto(e, "luogo_trasporto")}>
                                        <option value={0}>Seleziona...</option>
                                        {
                                            luoghi_trasporto?.map(el => <option value={el.id}>{el.detail}</option>)
                                        }


                                    </select>
                                </div>
                                <div className="col-span-1">
                                    <Input label="Luogo comune" type="text" name="luogo_comune" value={trasporto?.luogo_comune} onChange={(e) => handleChangeTrasporto(e)} />

                                </div>





                            </div>


                        </AccordionDetails>
                    </Accordion>

                    {scheda.disposizione == 1 ?
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<FiChevronDown />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <h6 className="font-bold"><div className="flex flex-row gap-2 justify-center items-center"><div><FaCircle className={atto?.data_stampa ? "text-green-900" : "text-red-700"} /></div><div>Dichiarazione sostitutiva di atto di notorietà</div></div></h6>
                            </AccordionSummary>
                            <AccordionDetails>


                                <div className="grid grid-cols-2 gap-2  bg-gray-100 ">



                                    <div className="col-span-1">
                                        <Input label="Numero parenti" type="text" name="numero_parenti" value={atto?.numero_parenti} onChange={(e) => handleChangeAtto(e)} />

                                    </div>
                                    <div className="col-span-4 md:col-span-1">

                                        <CustomCheckbox label="Stimolatore cardiaco" value={atto?.stimolatore_cardiaco} onClick={(e) => handleChangeCheckboxAtto(e, "stimolatore_cardiaco")} />
                                    </div>

                                    <div className="col-span-2 flex flex-row-reverse gap-2">
                                        <div> <Button icon={<FiPrinter />} label="Stampa" onClick={(e) => handleStampaDichiarazioneAtto(e)} /></div>
                                    </div>



                                </div>

                            </AccordionDetails>
                        </Accordion>
                        : ""}
                </div>
            </div>



        </>
    )

}


/*
const ModalTrasporto = ({ id_scheda, defunto, scheda }) => {
    const [open, setOpen] = useState(false);

    const [operazione, setOperazione] = useState("inserimento")



   


    
    const handleOpen = async () => {

        try {


            let resp = await api.get("/istanza_trasporto/" + id_scheda)
          
            if (resp.data.length > 0) {
                setTrasporto(resp.data[0])
                setOperazione("modifica");

            } else {

                setOperazione("inserimento");
           

        } catch {



        }

        setOpen(true);
    }

    const handleSave = async () => {

        try {

            if (operazione === "inserimento") {

                let res = await api.post("/istanza_trasporto", { istanza: trasporto })
            } else {
                console.log(trasporto)
                let res = await api.put("/istanza_trasporto", { istanza: trasporto })
            }

            let resp = await api.get("/istanza_trasporto/" + id_scheda)
            alert("Salvataggio con successo")
            handleStampa();
            //toast.success("Salvataggio avvenuto con successo")
        } catch {

            //toast.error("Errore durante il salvataggio");
            alert("errore")
        }

        setOpen(true);
    }


   

    return (
        <>
            <Button icon={<FiMove />} label="Genera trasporto" onClick={handleOpen} />
            <Modal open={open} onClose={() => setOpen(false)}>

              


            </Modal>


        </>


    )

}


*/


